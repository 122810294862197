import React, {useEffect, useState} from 'react';

function Table({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');


    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.filter((item) => {
        return item.DISPLAY_FIRST_LAST.toLowerCase().includes(filter.toLowerCase())
    }).sort((a, b) => b.MARKET_VALUE - a.MARKET_VALUE);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';


    return (<div style={{padding: '10px'}}>
        <h1 className="project-heading">
            <strong>NBA Players Market Value</strong>
        </h1>
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search"/>
        <table className={tableClassName} style={{color: 'white', margin: '0 auto'}}>
            <thead>
            <tr>
                <th style={{padding: '15px'}}>#</th>
                <th style={{padding: '15px'}}>Name</th>
                <th style={{padding: '15px'}}>Height</th>
                <th style={{padding: '15px'}}>Weight</th>
                <th style={{padding: '15px'}}>Birthdate</th>
                <th style={{padding: '15px'}}>Years Professional</th>
                <th style={{padding: '15px'}}>Team</th>
                <th style={{padding: '15px'}}>Position</th>
                <th style={{padding: '15px'}}>Market Value</th>
            </tr>
            </thead>
            <tbody style={{textAlign: 'center'}}>
            {filteredData.map((item, index) => (<tr key={index}>
                <td style={{padding: '15px'}}>{index + 1}</td>
                <td style={{padding: '15px'}}><a
                    href={`/players/${item.DISPLAY_FIRST_LAST}`}>{item.DISPLAY_FIRST_LAST}</a></td>
                <td style={{padding: '15px'}}>{item.HEIGHT} ft</td>
                <td style={{padding: '15px'}}>{item.WEIGHT} lbs</td>
                <td style={{padding: '15px'}}>{item.BIRTHDATE ? new Date(item.BIRTHDATE).toLocaleDateString() : null}</td>
                <td style={{padding: '15px'}}>{item.SEASON_EXP}</td>
                <td style={{padding: '15px'}}><a href={`/team/${item.TEAM_NAME}`}>{item.TEAM_CITY} {item.TEAM_NAME}</a>
                </td>
                <td style={{padding: '15px'}}>{item.POSITION}</td>
                <td>{item.MARKET_VALUE.toLocaleString(undefined, {maximumFractionDigits: 0}) + ' $'}</td>

            </tr>))}

            </tbody>
            <style>
                {`
        @media (max-width: 600px) {
          table th:nth-child(3),
          table td:nth-child(3),
          table th:nth-child(4),
          table td:nth-child(4),
          table th:nth-child(5),
          table td:nth-child(5),
          table th:nth-child(6),
          table td:nth-child(6),
          table td:nth-child(7),
          table th:nth-child(7),
          table td:nth-child(8),
          table th:nth-child(8){
            display: none;
          }
        }
      `}
            </style>
        </table>
    </div>);

}

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Switzerland":
            return "CH";
        case "Great Britain":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
        case "Norway":
            return "NO";
        case "Poland":
            return "PL";
        case "Hungary":
            return "HU";
        case "Romania":
            return "RO";
        case "Russia":
            return "RU";
        case "Turkey":
            return "TR";
        case "Brazil":
            return "BR";
        case "Mexico":
            return "MX";
        case "South Africa":
            return "ZA";
        case "China":
            return "CN";
        case "India":
            return "IN";
        case "Ecudor":
            return "EC";
        case "Colombia":
            return "CO";
        case "Chile":
            return "CL";
        case "Eritrea":
            return "ER";
        case "Egypt":
            return "EG";
        case "Morocco":
            return "MA";
        case "Tunisia":
            return "TN";
        case "USA":
            return "US";

        case "Israel":
            return "IL";
        case "Ukraine":
            return "UA";
        case "Kazakhstan":
            return "KZ";
        case "Latvia":
            return "LV";
        case "Lithuania":
            return "LT";
        case "Croatia":
            return "HR";
        case "Bulgaria":
            return "BG";
        case "Belarus":
            return "BY";
        case "Albania":
            return "AL";
        case "Andorra":
            return "AD";
        case "Armenia":
            return "AM";
        case "Azerbaijan":
            return "AZ";
        case "Bosnia and Herzegovina":
            return "BA";
        case "Cyprus":
            return "CY";
        case "Ecuador":
            return "EC";
        case "Thailand":
            return "TH";
        case "Iran":
            return "IR";
        case "United Arab Emirates":
            return "AE";


    }
}


export default Table;