import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {AiFillHome, AiFillInteraction, AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineTeam,} from "react-icons/ai";
import {useMediaQuery} from "react-responsive";
import CredentialsAPI from "./Credentials/CredentialsAPI";
import Autosuggest from 'react-autosuggest';
import RealmService from "./Country/RealmService";
import {NavDropdown} from "react-bootstrap";


const auctionsService = new RealmService('cycling-trbvl');


function NavBar() {
    const isMobile = useMediaQuery({maxWidth: 600});
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getSuggestions = async (value) => {
        await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

        const mongodb = await auctionsService.getMongoClient();
        if (mongodb) {
            const collection = mongodb.db('NBA').collection('Players');

            // Perform queries or operations on the collection to get suggestions
            const documents = await collection.find({
                DISPLAY_FIRST_LAST: {$regex: value, $options: 'i'},
            });

            return documents.map((doc) => doc.DISPLAY_FIRST_LAST);
        }

        return [];
    };

    const handleSearch = (event, {newValue}) => {
        setSearchQuery(newValue);
    };

    const onSuggestionsFetchRequested = async ({value}) => {
        if (value.length >= 3) {
            setIsLoading(true);
            const suggestions = await getSuggestions(value);
            setSuggestions(suggestions);
            setIsLoading(false);
        } else {
            setSuggestions([]);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const renderSuggestion = (suggestion) => {
        return (
            <div>
                <a href={`/players/${suggestion}`}>{suggestion}</a>
            </div>
        );
    };

    const onSuggestionSelected = (event, {suggestion}) => {
        // Handle suggestion selection and navigation
        // You can use React Router's Link component to navigate to the corresponding page
        setSearchQuery(suggestion); // Update the searchQuery state to display the selected suggestion
    };

    const inputProps = {
        placeholder: 'Search',
        value: searchQuery,
        onChange: handleSearch,
    };

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);

      return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="lg"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>

        {isMobile && (
          <Navbar.Toggle
            onClick={() => updateExpanded(!expand)}
            aria-controls="responsive-navbar-nav"
          />
        )}

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                <AiFillHome style={{marginBottom: "2px"}}/> Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/teams" onClick={() => updateExpanded(false)}>
                                <AiOutlineTeam style={{marginBottom: "2px"}}/> Teams
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/predicted" onClick={() => updateExpanded(false)}>
                                <AiFillInteraction style={{marginBottom: "2px"}}/> Predicted Market Value
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title="Progression">
                                <NavDropdown.Item as={Link} to="/improving" onClick={() => updateExpanded(false)}>
                                    <AiOutlineArrowUp style={{marginBottom: "2px"}}/> Improved Players
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/declining" onClick={() => updateExpanded(false)}>
                                    <AiOutlineArrowDown style={{marginBottom: "2px"}}/> Declining Players
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>


                    </Nav>
                </Navbar.Collapse>
      </Container>
    </Navbar>




    );
}

export default NavBar;
