import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import {useParams} from "react-router-dom";
import BarChart from "./BarChart";
import Table from "./Table";
import RadarChart from "./RadarChart";
import Arrow from "./Arrow";
import ReactCountryFlag from "react-country-flag";
import BarChartAssists from "./BarChartAssists";
import BarChartRebounds from "./BarChartRebounds";
import BarChartFieldGold from "./BarChartFieldGold";
import BarChartStealsBlocks from "./BarChartStealsBlocks";
import BarChartTurnovers from "./BarChartTurnovers";
import BarChartFouls from "./BarChartFouls";
import BarChartMinutes from "./BarChartMinutes";
import BarChartGamesPlayed from "./BarChartGamesPlayed";
import TablePredicted from "./TablePredicted";
import TableSimilar from "./TableSimilar";
import TableAwards from "./TableAwards";
import {Helmet} from "react-helmet";


const auctionsService = new RealmService('cycling-trbvl');

const RiderDetails = () => {
    const {name} = useParams();
    const [queryResult, setQueryResult] = useState([]);
    const [queryResult2, setQueryResult2] = useState([]);
    const [queryResult3, setQueryResult3] = useState([]);
    const [queryResult4, setQueryResult4] = useState([]);
    const [details, setDetails] = useState([]);
    const [details2, setDetails2] = useState([]);
    const [queryResult5, setQueryResult5] = useState([]);
    const [queryResult6, setQueryResult6] = useState([]);
    const [queryResult7, setQueryResult7] = useState([]);
    const [queryResult8, setQueryResult8] = useState([]);
    const [queryResult9, setQueryResult9] = useState([]);
    const [maxValue, setMaxValue] = useState([]);
    const [similarRiders, setSimilarRiders] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            let points_per_season = null;
            let current_stats = null;
            let radar_stats = null;

            await auctionsService.initialize('cycling-trbvl', CredentialsAPI.API_CYCLING);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('NBA').collection('Players');
                const collection_stats = mongodb.db('NBA').collection('Stats');
                const collection_predicted_stats = mongodb.db('NBA').collection('Predicted_Stats');
                const collection_awards = mongodb.db('NBA').collection('Awards');
                // Perform queries or operations on the collection

                // Example: Fetch all documents from the collection
                const documents = await collection.find({DISPLAY_FIRST_LAST: name});
                console.log(name)
                console.log(documents[0].BIRTHDATE)
                console.log("FIN")
                let stats = null;
                let predicted_stats = null;
                let stats_historic = null;
                console.log(documents)
                if (documents) {
                    stats = await collection.find({_id: documents[0]._id});
                    predicted_stats = await collection_predicted_stats.find({PLAYER_ID: documents[0]._id});
                    if (predicted_stats && predicted_stats[0]) {
                        setQueryResult8(predicted_stats[0])

                        const query = {
                            $and: [
                                {PTS_GP: {$gte: predicted_stats[0]['PTS_GP'] - 5}},
                                {PTS_GP: {$lte: predicted_stats[0]['PTS_GP'] + 5}},
                                {REB_GP: {$gte: predicted_stats[0]['REB_GP'] - 3}},
                                {REB_GP: {$lte: predicted_stats[0]['REB_GP'] + 3}},
                                {AST_GP: {$gte: predicted_stats[0]['AST_GP'] - 3}},
                                {AST_GP: {$lte: predicted_stats[0]['AST_GP'] + 3}},
                                {BLK_GP: {$gte: predicted_stats[0]['BLK_GP'] - 1}},
                                {BLK_GP: {$lte: predicted_stats[0]['BLK_GP'] + 1}},
                                {STL_GP: {$gte: predicted_stats[0]['STL_GP'] - 1}},
                                {STL_GP: {$lte: predicted_stats[0]['STL_GP'] + 1}},
                                {PLAYER_ID: {$ne: (predicted_stats[0].PLAYER_ID)}}
                            ],
                        };

                        const similar_cyclists = await collection_predicted_stats.aggregate([
                            {$match: query}, // Your initial query (optional)
                            {$sample: {size: 5}}
                        ])


                        setSimilarRiders(similar_cyclists)
                        const similar_player_name = await collection.find({_id: {$in: similar_cyclists.map((item) => item.PLAYER_ID)}})
                        console.log(similar_player_name)
                        setQueryResult4(similar_player_name)
                    }
                    const awards = await collection_awards.find({_id: documents[0]._id});
                    if (awards && awards[0]) {
                        console.log(awards)
                        setQueryResult9(awards[0].awards)
                    }
                    const stats_historic = await collection_stats.find({_id: documents[0]._id});
                    if (stats_historic) {
                        current_stats = stats_historic[0].stats.reverse()
                        radar_stats = current_stats[0]
                        radar_stats = {
                            "Points": radar_stats.PTS_GP / 30,
                            "Rebounds": radar_stats.REB_GP / 12.5,
                            "Assists": radar_stats.AST_GP / 11,
                            "Blocks": radar_stats.BLK_GP / 3.1,
                            "Steals": radar_stats.STL_GP / 2.1,
                        }
                        setQueryResult7(radar_stats)
                    }

                    points_per_season = stats_historic[0].stats.map((item) => {
                        return {
                            "season": item.SEASON_ID,
                            "points": item.PTS_GP,
                            "rebounds": item.REB_GP,
                            "assists": item.AST_GP,
                            "blocks": item.BLK_GP,
                            "steals": item.STL_GP,
                            "turnovers": item.TOV_GP,
                            "rebounds_def": item.DREB_GP,
                            "rebounds_off": item.OREB_GP,
                            "FG_PCT": item.FG_PCT,
                            "FG3_PCT": item.FG3_PCT,
                            "FT_PCT": item.FT_PCT,
                            "GP": item.GP,
                            "PF_GP": item.PF_GP,
                            "MIN_GP": item.MIN_GP,
                            "GS": item.GS

                        }
                    });
                    setQueryResult5(points_per_season.reverse())
                    setQueryResult6(current_stats)


                    const rank_per_season = documents[0].historic

                    if (documents) {


                        setDetails({
                            birthdate: documents[0].BIRTHDATE,
                            name: documents[0].DISPLAY_FIRST_LAST,
                            height: documents[0].HEIGHT,
                            weight: documents[0].WEIGHT,
                            country: documents[0].COUNTRY,
                            team_city: documents[0].TEAM_CITY,
                            team: documents[0].TEAM_NAME,
                            count_years: documents[0].SEASON_EXP,
                            school: documents[0].SCHOOL,
                            position: documents[0].POSITION,
                            market_value: documents[0].MARKET_VALUE,
                            age: calculateAge(documents[0].BIRTHDATE),
                            player_id: documents[0]._id
                            // Include the age field by calling the calculateAge function

                        });
                    }


                    function calculateAge(birthdate) {
                        if (birthdate) {
                            const birthYear = new Date(birthdate).getFullYear();
                            const currentYear = new Date().getFullYear();
                            return currentYear - birthYear;
                        } else {
                            return null;
                        }
                    }


                    let results = []; // Assuming documents is an array containing the necessary data

                    if (documents[0]?.results != null) {

                        results = documents[0].results.reverse().map((item) => {
                            const date = new Date(item.Date);
                            const formattedDate = date.toLocaleDateString('en-GB'); // 'en-GB' represents the format 'dd/mm/yyyy'

                            return {
                                "Datetime": date, "Date": formattedDate, "Pos": item.Pos, "Race": item.Race
                            };
                        }).sort((a, b) => new Date(b.Datetime) - new Date(a.Datetime));
                    } else {
                        console.log('Results is null or undefined.');
                    }


                    let teams_history = []; // Assuming documents is an array containing the necessary data

                    if (documents[0]?.teams_history != null) {
                        teams_history = documents[0].teams_history.reverse().map((item) => {
                            return {
                                "season": item.season, "team": item.team_name, "class": item.class
                            }
                        });
                    } else {
                        console.log('teams_history is null or undefined.');
                    }

                    let modes = []; // Assuming documents is an array containing the necessary data
                    let modes_2


// Now find the second maxKey after xremoving the first occurrence


                    let market_value_history = []; // Assuming documents is an array containing the necessary data

                    if (documents[0]?.market_value_history != null) {
                        market_value_history = documents[0].market_value_history.map((item) => {
                            const dateObj = new Date(item.date);
                            const formattedDate = dateObj.toLocaleDateString('en-GB'); // Use 'en-GB' for dd/mm/yyyy format, adjust to your locale if needed

                            return {
                                "date": formattedDate, "value": item.value,
                            };
                        });
                    } else {
                        console.log('market_value_history is null or undefined.');
                    }


                    setQueryResult(points_per_season);
                    setQueryResult2(rank_per_season);
                    setQueryResult3(teams_history);

                }
            }
        };

        fetchData();
    }, []);


    return (<Container fluid className="project-section">
            <Container>
                <Helmet>
                    <title>Player Details: {name}</title>
                    <meta name="description" content={`Details for the player ${name}`}/>

                    {/* Open Graph (OG) meta tags */}
                    <meta property="og:url" content="https://www.nba.mrfreire.net/players/"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title"  content={`Details for the NBA player ${name}`}/>
                    <meta property="og:description"  content={`Details and market value for the NBA player ${name}`}/>
                    <meta property="og:image" content={'https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/' + details.player_id + '.png'}/>

                </Helmet>
                <h1 className="project-heading">
                    <Row style={{justifyContent: "center", padding: "20px"}}>
                        <Col md={4} sm={4}>
                            <strong>{details.name}  </strong>
                                                        <ReactCountryFlag countryCode={retrieveCountryCode(details.country)}/>
                        </Col>
                        <Col md={4} sm={4}>
                        </Col>

                        <Col md={4} sm={4}>
                            <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                                <img
                                    src={'https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/' + details.player_id + '.png'}
                                    style={{
                                        width: "20%",
                                        height: "20%",
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </h1>


                <Row style={{justifyContent: "center", padding: "20px"}}>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Birthdate</h6>
                            <strong>{details.birthdate ? new Date(details.birthdate).toLocaleDateString() : null}</strong>
                        </div>

                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Age</h6>
                            <strong>{details.age}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Height</h6>
                            <strong>{details.height} ft</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Weight</h6>
                            <strong>{details.weight} lbs</strong>
                        </div>
                    </Col>


                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Country</h6>
                            <strong><a href={"/country/" + details.country}>{details.country}</a></strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Position</h6>
                            <strong>{details.position}</strong>
                        </div>
                    </Col>
                </Row>

                <Row style={{justifyContent: "center", padding: "20px"}}>


                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Team</h6>
                            <strong><a href={"/team/" + details.team}>{details.team}</a></strong>
                        </div>
                    </Col>
                    <Col>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Years Professional</h6>
                            <strong> {details.count_years}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>City</h6>
                            <strong> {details.team_city}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Team</h6>
                            <strong> {details.team}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Market Value</h6>
                            <strong>{details.market_value ? details.market_value.toLocaleString() + ' $' : ''}</strong>
                        </div>
                    </Col>
                    <Col md={2} sm={2}>
                        <div style={{overflow: "auto", alignContent: "center", color: "white"}}>
                            <h6>Trend</h6>
                            <strong><Arrow market_value_history=''/></strong>
                        </div>
                    </Col>
                </Row>

                <Container>
                    <h4 className="project-heading-2">
                        <strong>Historic Results</strong>
                    </h4>

                </Container>
                <Container>

                    <Row style={{justifyContent: "center", padding: "20px"}}>
                        <Col md={9} sm={9}>
                            <div style={{overflow: "auto", alignContent: "left"}}>
                                <Table
                                    data={queryResult6}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                            <p>
                                <hr></hr>
                            </p>

                            <h4 className="project-heading-2">
                                <strong>Predicted Statistics</strong>
                            </h4>

                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TablePredicted
                                    data={queryResult8}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>


                        <Col md={3} sm={3}>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <RadarChart
                                    data={queryResult7}
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>

                            <h6 className="project-heading-2">
                                <strong>Similar Players</strong>
                            </h6>
                            <div style={{overflow: "auto", alignContent: "center"}}>
                                <TableSimilar
                                    data={queryResult4}
                                    className="custom-table"
                                    style={{color: "white", padding: "20px"}}
                                />
                            </div>
                        </Col>

                    </Row>
                </Container>
                <h4 className="project-heading-2">
                    <strong>Statistics</strong>
                </h4>

                <Container>
                    <Container>
                        <Row style={{justifyContent: "center", padding: "20px"}}>
                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChart
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartAssists
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartRebounds
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartStealsBlocks
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartTurnovers
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>


                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartFieldGold
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>


                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartFouls
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartMinutes
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>

                            <Col md={4} sm={4}>
                                <div style={{overflow: "auto", alignContent: "center"}}>
                                    <BarChartGamesPlayed
                                        data={queryResult5}
                                        className="custom-table"
                                        style={{color: "white", padding: "40px"}}
                                    />
                                </div>
                            </Col>


                        </Row>
                    </Container>
                </Container>
                {queryResult9 && queryResult9[0] ? (
                    <div>
                        <h4 className="project-heading-2">
                            <strong>Awards</strong>
                        </h4>
                        <Container>
                            <Container>
                                <Row style={{justifyContent: "center", padding: "20px"}}>
                                    <Col md={4} sm={4}>
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <TableAwards
                                                data={queryResult9}
                                                className="custom-table"
                                                style={{color: "white", padding: "40px"}}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </div>
                ) : null}


            </Container>


        </Container>


    );


};


function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Switzerland":
            return "CH";
        case "Great Britain":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
        case "Norway":
            return "NO";
        case "Poland":
            return "PL";
        case "Hungary":
            return "HU";
        case "Romania":
            return "RO";
        case "Russia":
            return "RU";
        case "Turkey":
            return "TR";
        case "Brazil":
            return "BR";
        case "Mexico":
            return "MX";
        case "South Africa":
            return "ZA";
        case "China":
            return "CN";
        case "India":
            return "IN";
        case "Ecudor":
            return "EC";
        case "Colombia":
            return "CO";
        case "Chile":
            return "CL";
        case "Eritrea":
            return "ER";
        case "Egypt":
            return "EG";
        case "Morocco":
            return "MA";
        case "Tunisia":
            return "TN";
        case "USA":
            return "US";

        case "Israel":
            return "IL";
        case "Ukraine":
            return "UA";
        case "Kazakhstan":
            return "KZ";
        case "Latvia":
            return "LV";
        case "Lithuania":
            return "LT";
        case "Croatia":
            return "HR";
        case "Bulgaria":
            return "BG";
        case "Belarus":
            return "BY";
        case "Albania":
            return "AL";
        case "Andorra":
            return "AD";
        case "Armenia":
            return "AM";
        case "Azerbaijan":
            return "AZ";
        case "Bosnia and Herzegovina":
            return "BA";
        case "Cyprus":
            return "CY";
        case "Ecuador":
            return "EC";
        case "Thailand":
            return "TH";
        case "Iran":
            return "IR";
        case "United Arab Emirates":
            return "AE";


    }
}


export default RiderDetails;
