import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function BarChart({ data }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      console.log(data)

      const labels = data.map(item => item.season); // Extracting labels from the data array
      const values = data.map(item => item.GP);
      const values2 = data.map(item => item.GS);
      // Extracting values from the data array
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Games Played',
              data: values,
              backgroundColor: 'rgba(115, 147, 179, 0.8)',
              borderColor: 'rgba(115, 147, 179, 1)',
              borderWidth: 1,
            },
            {
                label: 'Games Started',
                data: values2,
                backgroundColor: 'rgba(25, 25, 112, 0.8)',
                borderColor: 'rgba(25, 25, 112, 1)',
                borderWidth: 1,
                }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef} />;
}

export default BarChart;
