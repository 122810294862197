import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import RiderDetails from "./components/RiderDetails/RiderDetails";
import RidersTeam from "./components/TeamDetails/RidersTeam";
import RidersCountry from "./components/Country/RidersCountry";
import RidersLastYear from "./components/MarketValue/RidersLastYear";
import Teams from "./components/MarketValue/Teams";
import Riders from "./components/MarketValue/Riders";
import RidersDeclining from "./components/Surprising/RidersDeclining";
import RidersPredicted from "./components/Predicted/RidersPredicted";
import RidersSurprising from "./components/Surprising/RidersSurprising";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  // Sitemap URLs configuration
  const sitemapUrls = [
    { url: '/', changefreq: 'daily', priority: 1.0 },
    { url: '/players/:name', changefreq: 'daily', priority: 0.9 },
    { url: '/team/:teamName', changefreq: 'daily', priority: 0.9 },
    { url: '/country/:land', changefreq: 'daily', priority: 0.9 },
    { url: '/last-year', changefreq: 'daily', priority: 0.8 },
    { url: '/teams', changefreq: 'daily', priority: 0.8 },
    { url: '/predicted', changefreq: 'daily', priority: 0.8 },
  ];

  // Sitemap generation code


  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Riders />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/players/:name" element={<RiderDetails />} />
          <Route path="/team/:teamName" element={<RidersTeam />} />
          <Route path="/country/:land" element={<RidersCountry />} />
          <Route path="/last-year" element={<RidersLastYear />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/predicted" element={<RidersPredicted />} />
                    <Route path="/improving" element={<RidersSurprising />} />
                    <Route path="/declining" element={<RidersDeclining />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
