import React, {useEffect, useState} from 'react';

function Table({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');
    console.log(data)

    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data
    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';


    return (<div style={{padding: '10px'}}>
        <table className={tableClassName} style={{color: 'white', margin: '0 auto'}}>
            <thead>
            <tr>
                <th style={{padding: '20px'}}>Season</th>
                <th style={{padding: '20px'}}>Points</th>
                <th style={{padding: '20px'}}>Rebounds</th>
                <th style={{padding: '20px'}}>Assists</th>
                <th style={{padding: '20px'}}>Blocks</th>
                <th style={{padding: '20px'}}>Steals</th>
                <th style={{padding: '20px'}}>Minutes</th>
                <th style={{padding: '20px'}}>FG</th>
                <th style={{padding: '20px'}}>3PT</th>
                <th style={{padding: '20px'}}>FT</th>
            </tr>
            </thead>
            <tbody style={{textAlign: 'center'}}>
            {filteredData.map((item, index) => (<tr key={index}>
                <td>{item.SEASON_ID}</td>
                <td>{item.PTS_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{item.REB_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{item.AST_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{item.BLK_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{item.STL_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{item.MIN_GP.toLocaleString(undefined, {maximumFractionDigits: 1})}</td>
                <td>{(item.FG_PCT * 100).toLocaleString(undefined, {maximumFractionDigits: 1})} %</td>
                <td>{(item.FG3_PCT * 100).toLocaleString(undefined, {maximumFractionDigits: 1})} %</td>
                <td>{(item.FT_PCT * 100).toLocaleString(undefined, {maximumFractionDigits: 1})} %</td>
            </tr>))}

            </tbody>
            <style>
                {`
        @media (max-width: 600px) {

          table th:nth-child(4),
          table td:nth-child(4),
          table th:nth-child(5),
          table td:nth-child(5),
          table th:nth-child(6),
          table td:nth-child(6),
                                 table th:nth-child(7),
          table td:nth-child(7),{
            display: none;
          }
        }
      `}
            </style>
        </table>
    </div>);

}

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Switzerland":
            return "CH";
        case "Great Britain":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
        case "Norway":
            return "NO";
        case "Poland":
            return "PL";
        case "Hungary":
            return "HU";
        case "Romania":
            return "RO";
        case "Russia":
            return "RU";
        case "Turkey":
            return "TR";
        case "Brazil":
            return "BR";
        case "Mexico":
            return "MX";
        case "South Africa":
            return "ZA";
        case "China":
            return "CN";
        case "India":
            return "IN";
        case "Ecudor":
            return "EC";
        case "Colombia":
            return "CO";
        case "Chile":
            return "CL";
        case "Eritrea":
            return "ER";
        case "Egypt":
            return "EG";
        case "Morocco":
            return "MA";
        case "Tunisia":
            return "TN";
        case "USA":
            return "US";

    }
}


export default Table;