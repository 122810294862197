import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function BarChart({ data }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }


      const labels = data.map(item => item.season); // Extracting labels from the data array
      const values = data.map(item => item.FG_PCT);
      const values2 = data.map(item => item.FG3_PCT);
        const values3 = data.map(item => item.FT_PCT);
      // Extracting values from the data array
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Field Goal Percentage',
              data: values,
              backgroundColor: 'rgba(159, 226, 191, 1)',
              borderColor: 'rgba(159, 226, 191, 0.8)',
              borderWidth: 1,
            },
            {
                label: '3-Point Field Goal Percentage',
                data: values2,
                backgroundColor: 'rgba(0, 128, 128, 1)',
                borderColor: 'rgba(0, 128, 128, 0.8)',
                borderWidth: 1,
                },
                {
                    label: 'Free Throw Percentage',
                    data: values3,
                    backgroundColor: 'rgba(20, 52, 164, 1)',
                    borderColor: 'rgba(20, 52, 164, 0.8)',
                    borderWidth: 1,

            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef} />;
}

export default BarChart;
