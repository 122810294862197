import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function BarChart({ data }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      console.log(data)

      const labels = data.map(item => item.season); // Extracting labels from the data array
      const values = data.map(item => item.PF_GP); // Extracting values from the data array
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Personal Fouls',
              data: values,
              backgroundColor: 'rgba(4, 59, 92, 0.8)',
              borderColor: 'rgba(4, 59, 92, 1)',
              borderWidth: 1,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef} />;
}

export default BarChart;
