import React, {useEffect, useRef} from 'react';
import {Chart} from 'chart.js/auto';

function BarChart({data}) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartRef && chartRef.current) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            console.log(data)

            const labels = data.map(item => item.season); // Extracting labels from the data array
            const values = data.map(item => item.rebounds);
            const values2 = data.map(item => item.rebounds_def);
            const values3 = data.map(item => item.rebounds_off);
            // Extracting values from the data array
            chartInstanceRef.current = new Chart(chartRef.current, {
                type: 'bar', data: {
                    labels: labels, datasets: [{
                        label: 'Rebounds',
                        data: values,
                        backgroundColor: 'rgba(96, 130, 182, 0.8)',
                        borderColor: 'rgba(96, 130, 182, 1)',
                        borderWidth: 1,
                    }, {
                        label: 'Defensive Rebounds',
                        data: values2,
                        backgroundColor: 'rgba(0, 163, 108, 0.8)',
                        borderColor: 'rgba(0, 163, 108, 1)',
                        borderWidth: 1,
                    }, {
                        label: 'Offensive Rebounds',
                        data: values3,
                        backgroundColor: 'rgba(173, 216, 230, 1)',
                        borderColor: 'rgba(173, 216, 230, 0.8)',
                        borderWidth: 1,

                    }],
                }, options: {
                    responsive: true, maintainAspectRatio: false, scales: {
                        x: {
                            beginAtZero: true, stacked: true
                        }, y: {
                            beginAtZero: true, stacked: true
                        },
                    },
                }, plugins: {
                    legend: {
                        display: true, labels: {
                            font: {
                                size: 4, // Adjust the font size for the legend labels
                            },
                        },
                    },
                },

            });
        }
    }, [data]);

    return <canvas ref={chartRef}/>;
}

export default BarChart;
