import React, {useEffect, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import Table from "./Table";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import {useParams} from "react-router-dom";


const auctionsService = new RealmService('cycling-trbvl');

const RidersCountry = () => {
  const [queryResult, setQueryResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {land} = useParams();


  useEffect(() => {
    const fetchData = async () => {

      await auctionsService.initialize(     'cycling-trbvl', CredentialsAPI.API_CYCLING);

      const mongodb = await auctionsService.getMongoClient();
      if (mongodb) {
        const collection = mongodb.db('NBA').collection('Players');

        // Perform queries or operations on the collection

        // Example: Fetch all documents from the collection
                const documents = await collection.find({COUNTRY: (land)});
              setIsLoading(false);
              console.log(documents);  
        setQueryResult(documents);
      }
    };

    fetchData();
  }, []);


        return (
            <Container fluid className="project-section">
                <Container><h1 className="project-heading">
                    <strong>{land}</strong>
                </h1>
                       <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
      <Col md={12} sm={6}>
        <div style={{ overflow: 'auto', alignContent: 'center' }}>
          {isLoading ? (
            // Show a loading spinner or loading indicator while data is being fetched
            <Spinner animation="border" role="status" color={"white"}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            // Render the table when the data is loaded
            <Table
              data={queryResult}
              className="custom-table"
              style={{ color: 'white', paddingRight: '10px' }}
            />
          )}
        </div>
      </Col>
    </Row>

                </Container>
            </Container>
        );
};

export default RidersCountry;
